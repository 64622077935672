@media (max-width: 768px) {
    .main-container>.header-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        border-color: transparent;
        z-index: 20;
        transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: translateX(-110%);
    }

    .main-container .hamburger-menu {
        display: grid;
        align-items: center;
        justify-items: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .header-wrapper.open {
        transform: translateX(0);
        background: linear-gradient(45deg, #dff4e4 0%, #f0ddee 24%, #dee7f5 49%, #e9dae4 73%, #cbffff 100%);
        background-blend-mode: overlay, normal;
        box-shadow: 0px 4.880000114440918px 29.280000686645508px -1.2200000286102295px rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(24px);
    }

    .main-container .hamburger-menu.active>.line:first-child {
        transform: rotate(45deg) translate(7px, 8px);
    }

    .main-container .hamburger-menu.active>.line:nth-child(2) {
        opacity: 0;
    }

    .main-container .hamburger-menu.active>.line:last-child {
        transform: rotate(-45deg) translate(8px, -8px);
    }

    .main-container>.content {
        width: 100% !important;
    }

    /* Home Tab */
    .HomeTab-container .inner-container {
        flex-direction: column-reverse;
        padding-top: 1rem;
    }

    .HomeTab-container .contact-container {
        display: none;
    }

    /* About Tab */
    .AboutTab-container .inner-container .image-container {
        grid-column: 2 /3;
    }

    .AboutTab-container .inner-container .image-container img {
        max-height: 200px;
    }

    .AboutTab-container .inner-container .content-container {
        grid-column: 1/ 4;
    }

    .AboutTab-container .inner-container .image-container,
    .AboutTab-container .inner-container .content-container .card.info {
        grid-template-columns: repeat(1, 1fr);
    }

    .AboutTab-container .inner-container .downloadCV-container {
        width: 100%;
        grid-column: 1/ 4;
        order: 4;
    }

    .AboutTab-container .inner-container .subcontent-container.card.info {
        grid-column: 1/ 4;

    }

    .AboutTab-container .inner-container .downloadCV-container button {
        width: 100%;
        text-align: center;
        display: block;
    }

    /* Portfolio Tab */
    .PortfolioTab-container .inner-container {
        grid-template-columns: repeat(1, 1fr);
    }

    /* Contact Tab */
    .ContactTab-container .inner-container .form-container form .input-group.inputName,
    .ContactTab-container .inner-container .form-container form .input-group.inputEmail {
        flex-basis: 100%;
        max-width: 100%;
    }

    .ContactTab-container .inner-container .form-container form .button-container button {
        width: 100%;
    }
}