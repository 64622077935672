.PortfolioTab-container {
    padding: 1rem;
}

.PortfolioTab-container .inner-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}
.PortfolioTab-container .inner-container .projectBox {
    border-radius: var(--radius);
    display: grid;
    gap: 10px;
    background: rgba(255, 255, 255, 0.1); /* Yarı saydam beyaz arka plan */
    transition: .25s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;

    /* Glassmorphism efekti */
    backdrop-filter: blur(10px); /* Arka planı bulanıklaştır */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.PortfolioTab-container .inner-container .projectBox:hover {
    background: rgba(255, 255, 255, 0.2); /* Hover durumunda daha opak arka plan */
}


/* .PortfolioTab-container .inner-container .projectBox .img-container {
    border-radius: var(--radius-md);
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: .5rem;
} */

.PortfolioTab-container .inner-container .projectBox .content-container,
.PortfolioTab-container .inner-container .projectBox .button-container {
    padding: 0 .5rem .5rem .5rem;
}

.PortfolioTab-container .inner-container .projectBox .blank {
    width: 100%;
    height: 200px;
    background-color: #fff;
}

.PortfolioTab-container .inner-container .projectBox .title {
    color: var(--text-primary);
    @apply font-medium text-gray-600 text-lg my-2 uppercase;
}
.PortfolioTab-container .inner-container .projectBox .description {
    color: var(--text-primary);
    @apply text-xs;
}
.PortfolioTab-container .inner-container .projectBox .button-container {
    width: 100%;
    position: relative;
    display: flex; /* Flexbox düzeni */
    padding: 0;
    padding: 0 .5rem .5rem .5rem;
}

.PortfolioTab-container .inner-container .projectBox .button-container a {
    background-color: var(--orange-500);
    color: #fff;
    border-radius: var(--radius-md);
    padding: 5px 20px;
    font-size: .9rem;
    font-weight: 600;
    display: flex; /* İçerik merkezi düzenleme */
    align-items: center; /* Yükseklik boyunca ortalama */
    justify-content: center; /* Genişlik boyunca ortalama */
    text-align: center; /* Metin merkezleme */
    text-decoration: none; /* Alt çizgi kaldırma */
    transition: background-color 1s cubic-bezier(0.165, 0.84, 0.44, 1); /* Sadece arka plan renginin geçişi */
    width: 100%;
    height: 100%;
}

.PortfolioTab-container .inner-container .projectBox .button-container a:hover {
    background-color: var(--orange-600);
    color: #fff;
}
