/* MAIN HEADER CONTAINER */
.main-container .header-wrapper {
    width: 20%;
    display: grid;
    padding: 1rem;
    border-right: 1px solid #fff;
    position: relative;
    z-index: 5;
}

.main-container .hamburger-menu {
    display: none;
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: .25rem;
    border-radius: .35rem;
    margin-left: auto;
    position: absolute;
    top: 2%;
    right: 5%;
    z-index: 999;
}

.main-container .hamburger-menu .line {
    width: 80%;
    height: 3px;
    border-radius: 1px;
    background-color: var(--text-primary);
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.main-container .header-wrapper .content>div {
    flex: 1;
}

.main-container .header-wrapper .content>div.body {
    flex: 2;
}

.main-container>.header-wrapper>.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* header-wrapper header */
.main-container .header-wrapper .header {
    display: grid;
    gap: 10px;
}

.main-container .header-wrapper .header .img-container img {
    border-radius: var(--radius-md);
    margin: auto;
    /* border: 2px solid #fff; */
    width: 50%;
    aspect-ratio: 1/ 1;
    object-fit: cover;
}

.main-container .header-wrapper .header .text-container {
    text-align: center;
}

.main-container .header-wrapper .header .text-container h4 {
    font-weight: 600;
    color: var(--text-primary);
}

.main-container .header-wrapper .header .text-container h6 {
    font-weight: 400;
    color: var(--text-secondary);
}

/* header-wrapper header */
.main-container .header-wrapper .body nav li {
    transition: all 0.2s ease-in-out;
    margin-bottom: 10px;
}

.main-container .header-wrapper .body nav li .navLink {
    padding: .5rem .5rem .5rem 1rem;
    border-radius: var(--radius);
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    font-size: .9rem;
    color: var(--text-secondary);
    transition: all .3s ease-in-out;
}

.main-container .header-wrapper .body nav li .navLink:hover {
    padding-left: 1.5rem;
    color: var(--orange-500);
}

.main-container .header-wrapper .body nav li .navLink.active {
    background-color: #fff;
}

.main-container .header-wrapper .body nav li .navLink.active i {
    color: var(--orange-500);
}

/* END MAIN HEADER CONTAINER */


/* START MAIN FOOTER CONTAINER */
.main-container .header-wrapper .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.main-container .header-wrapper .footer .socials {
    display: flex;
    justify-content: center;
    gap: .5rem;
}

.main-container .header-wrapper .footer .socials .box a {
    background-color: #fff;
    padding: .5rem .75rem;
    border-radius: var(--radius-md);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.main-container .header-wrapper .footer .socials .box a:hover {
    background-color: #0077b5;
    color: #fff;
}

.main-container .header-wrapper .footer .copyright {
    color: var(--text-secondary);
    font-size: .6rem;
    text-align: center;
}

.main-container .header-wrapper .footer .localize {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

.main-container .header-wrapper .footer .localize .switch {
    padding: 5px 10px;
    border-radius: var(--radius-md);
    cursor: pointer;
    transition: 0.3s ease-in;
    /* font-weight: 600; */
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.main-container .header-wrapper .footer .localize .switch.on {
    background-color: var(--orange-500);
    color: #fff;
}

.main-container .header-wrapper .footer .localize .switch.off {
    background-color: #fff;
}

.main-container .header-wrapper .footer .localize .switch.off:hover {
    background-color: var(--orange-100);
}
.main-container .header-wrapper .footer .localize .switch.on:hover {
    background-color: var(--orange-600);
}

/* END MAIN FOOTER CONTAINER */